import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Top, Navbar, Footer } from "views";
import { TimeLine, Clients, Contact, Portfolio, Services } from "views/Sections";
import { SEO, LanguageSelector } from "components";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";

import "../style/main.scss";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query homePageQuery($langKey: String!) {
    site {
      meta: siteMetadata {
        keywords
        description
      }
    }
    sections: allMarkdownRemark(
      filter: {
        fields: { langKey: { eq: $langKey }, directoryName: { regex: "/sections/i" } }
        frontmatter: { anchor: { ne: null } }
      }
      sort: { order: ASC, fields: [fields___fileName] }
    ) {
      nodes {
        i18n: frontmatter {
          anchor
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        fields: {
          langKey: { eq: $langKey }
          fileName: { regex: "/services\\./i" }
          directoryName: { regex: "/sections/i" }
        }
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...ServicesMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    portfolio: allMarkdownRemark(
      filter: {
        fields: {
          langKey: { eq: $langKey }
          fileName: { regex: "/portfolio\\./i" }
          directoryName: { regex: "/sections/i" }
        }
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...PortfolioMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    timeline: allMarkdownRemark(
      filter: {
        fields: {
          langKey: { eq: $langKey }
          fileName: { regex: "/timeline\\./i" }
          directoryName: { regex: "/sections/i" }
        }
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...TimeLineMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    contact: allMarkdownRemark(
      filter: {
        fields: {
          langKey: { eq: $langKey }
          fileName: { regex: "/contact\\./i" }
          directoryName: { regex: "/sections/i" }
        }
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...ContactMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    clients: allMarkdownRemark(
      filter: {
        fields: {
          langKey: { eq: $langKey }
          fileName: { regex: "/clients\\./i" }
          directoryName: { regex: "/sections/i" }
        }
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...ClientsMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    navbar: allMarkdownRemark(
      filter: { 
        fields: { 
          langKey: { eq: $langKey }, 
          fileName: { regex: "/^NavBar\\./i" } 
        } 
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...NavbarMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    top: allMarkdownRemark(
      filter: { 
        fields: { 
          langKey: { eq: $langKey }, 
          fileName: { regex: "/^Top\\./i" } 
        } 
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...TopMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    footer: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey }, fileName: { regex: "/footer\\./i" } } }
    ) {
      nodes {
        i18n: frontmatter {
          ...FooterMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    sitemap: allMarkdownRemark(
      filter: { 
        fields: { 
          langKey: { eq: $langKey }, 
          fileName: { regex: "/^Sitemap\\./i" } 
        } 
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...LandingsNavigationMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    callback: allMarkdownRemark(
      filter: { 
        fields: { 
          langKey: { eq: $langKey }, 
          fileName: { regex: "/^Callback\\./i" } 
        } 
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...CallbackMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    contactUs: allMarkdownRemark(
      filter: { 
        fields: { 
          langKey: { eq: $langKey }, 
          fileName: { regex: "/^ContactUs\\./i" } 
        } 
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...SendmailMarkdownRemarkFragment
          ...ContactUsMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({ data, pageContext }) => {
  const { langKey, defaultLang, langTextMap, pagePath } = pageContext;
  const { anchors } = breakDownAllNodes(data.sections.nodes);
  const navBarNode = data.navbar.nodes[0];
  const topNode = data.top.nodes[0];
  const servicesNode = data.services.nodes[0];
  const portfolioNode = data.portfolio.nodes[0];
  const timelineNode = data.timeline.nodes[0];
  const contactNode = data.contact.nodes[0];
  const clientsNode = data.clients.nodes[0];
  const footerNode = data.footer.nodes[0];
  const callbackNode = data.callback.nodes[0];
  const contactUsNode = data.contactUs.nodes[0];
  const sitemapNode = data.sitemap.nodes[0];

  const seo = { lang: langKey, ...data.site.meta };

  return (
    <>
      <SEO title="Home" {...seo} />
      <Navbar anchors={anchors} frontmatter={navBarNode.i18n}>
        {langTextMap != null && Object.keys(langTextMap).length > 1 && (
          <LanguageSelector
            langKey={langKey}
            defaultLang={defaultLang}
            langTextMap={langTextMap}
            pagePath={pagePath}
          />
        )}
      </Navbar>
      <Top frontmatter={topNode.i18n} />
      <Services frontmatter={servicesNode.i18n} className="bg-light" />
      <Portfolio frontmatter={portfolioNode.i18n} className="bg-dark" />
      <TimeLine frontmatter={timelineNode.i18n} className="bg-light pb-4" />
      <Clients frontmatter={clientsNode.i18n} className="bg-light" />
      <Contact
        frontmatter={contactNode.i18n}
        callbackNode={callbackNode.i18n}
        contactUsNode={contactUsNode.i18n}
        className="bg-dark"
      />
      <Footer
        frontmatter={footerNode.i18n}
        navigationNode={sitemapNode.i18n}
        contactUsNode={contactUsNode.i18n}
      />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

IndexPage.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default IndexPage;
